import React, { Dispatch, SetStateAction, useState, useRef } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Item, applications, materials, processing } from "pages/applications";
import { SidebarSection } from "./sidebar-section";

type Props = {
  activeItem: Item;
  setActiveItem: Dispatch<SetStateAction<Item>>;
  onItemClick: (item: Item) => void;
};

export const Sidebar = ({ activeItem, setActiveItem, onItemClick }: Props) => {
  const { t } = useTranslation();
  const [openSection, setOpenSection] = useState<string | null>(t("common.words.materials"));

  const handleItemClick = (item: Item) => {
    setActiveItem(item);
    onItemClick(item);
  };

  return (
    <div className="flex flex-col gap-8 w-full sm:w-60 md:w-80 md:sticky md:top-[90px] md:max-h-[calc(100vh-90px)] shrink-0 bg-[#FAFBFC] py-2">
      <div className="flex flex-col gap-4">
        <SidebarSection
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          isOpen={t("common.words.materials") === openSection}
          sectionHeader={t("common.words.materials")}
          options={materials}
          setIsOpen={setOpenSection}
          onItemClick={handleItemClick}
        />
        <SidebarSection
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          isOpen={t("common.words.applications") === openSection}
          sectionHeader={t("common.words.applications")}
          options={applications}
          setIsOpen={setOpenSection}
          onItemClick={handleItemClick}
        />
        <SidebarSection
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          isOpen={t("common.words.processing") === openSection}
          sectionHeader={t("common.words.processing")}
          options={processing}
          setIsOpen={setOpenSection}
          onItemClick={handleItemClick}
        />
      </div>

      <div className="w-full h-[1px] shrink-0 bg-gray-200 max-w-[90%] mx-auto"></div>

      <div className="flex flex-col gap-3 px-6">
        <h3 className="text-xl text-neutral-600">{t("header.navigation-items.3.title")}</h3>
        <ul className="flex marker:text-theme-blue list-disc flex-col px-8 py-2 gap-2">
          <li>
            <a href="/case-study">{t("common.words.case-study")}</a>
          </li>
          <li>
            <a href="/white-paper">{t("common.words.white-paper")}</a>
          </li>
        </ul>
      </div>

      <div className="w-full h-[1px] shrink-0 bg-gray-200 max-w-[90%] mx-auto"></div>

      <div className="flex flex-col gap-3 px-6">
        <h3 className="text-xl text-neutral-600">{t("common.words.blog")}</h3>
        <ul className="flex marker:text-theme-blue list-disc flex-col px-8 py-2 gap-2">
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://blog.polymerize.io/category/polymerize"
            >
              Polymerize
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://blog.polymerize.io/category/ai/ml"
            >
              AI/ML
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://blog.polymerize.io/category/data_management"
            >
              {t("common.words.data-management")}
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://blog.polymerize.io/category/industry_news"
            >
              {t("common.words.industry-news")}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
