import React from "react";
import { useTranslation } from "react-i18next";

type Item = {
  name: string;
  remarks: string;
  image: string;
};

type Props = {
  items: Record<string, Item>;
};

export const Content = ({ items }: Props) => {

  const {t} = useTranslation();
  const itemArray = Object.values(items);

  return (
    <div>
      {itemArray.map((item) => (
        <div className="flex flex-col gap-4" key={item.name} id={item.name.toLowerCase()}>
          <h2 className="capitalise text-2xl font-bold tracking-wide">
            {t(`${`applications-page.materials.${item.name.toLowerCase()}`}`)}
          </h2>
          <div>
            <img src={item.image} alt={item.name} className="h-auto w-2/4 float-right rounded ml-2" />
            <p className="inline leading-7">{item.remarks}</p>
          </div>
          <div className="h-[1px] sm:block shrink-0 grow bg-gray-200 mb-8 mt-8" />
        </div>
      ))}
    </div>
  );
};
