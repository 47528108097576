import React, { useEffect, useRef, useState } from "react"
import { Container } from "components/library"
import { Link, graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import PolymersImage from "images/applications/polymers.png"
import MasterBatchImage from "images/applications/masterbatch.jpg"
import AdhesiveImage from "images/applications/adhesive.jpg"
import TextileEngineeringImage from "images/applications/texttile.jpg"
import AutomotiveImage from "images/applications/automotiveIndustry.jpg"
import EnergyImage from "images/applications/energy.jpg"
import PaintImage from "images/applications/paint.jpg"
import Printing3DImage from "images/applications/printing3d.jpg"
import RecycleImage from "images/applications/recycle.jpg"
import RubberImage from "images/applications/rubber.jpg"
import FoodPackagingImage from "images/applications/foodPackaging.jpg"
import OilAndGasImage from "images/applications/oilAndGas.jpg"
import MembraneTechnologyImage from "images/applications/membraneTechnology.jpg"
import ApplicationsHero from 'images/applications/application-hero.jpg'
import { ContactUs } from "components/common/contact-us"
import Banner from "components/pages/applications/banner"
import { scroller } from "react-scroll"
import { CtaBlock } from "components/common/cta-block"
import { Sidebar } from "components/pages/applications/sidebar"
import { Content } from "components/pages/applications/content"
import { ArrowRight } from "react-feather"
import { SolutionsBannerIcon } from "components/library/icons/header/solutions-banner-icon"
export const materials = [
  "polymers",
  "rubber"
] as const

export const applications = [
  "adhesives",
  "paints and coatings",
  "automotive industry",
  "membrane technology",
  "energy deviced and semiconductors",
  "oil and gas industries",
  "packaging industries",
  "textile engineering"
] as const

export const processing = [
  "3d printing",
  "masterbatch/compounding",
  "recycling and sustainable materials effort"
] as const

export const items = [...materials, ...applications, ...processing]

type Material = typeof materials[number]
type Application = typeof applications[number]
type Processing = typeof processing[number]
export type Item = Material | Application | Processing

const Applications = () => {
  const { t } = useTranslation()
  const content: {
    [key in Item]: {
      name: string;
      remarks: string;
      image: string;
      type: "material" | "application" | "processing";
    };
  } = {
    polymers: {
      name: "Polymers",
      type: "material",
      image: PolymersImage,
      remarks: t('applications-page.remarks.polymers')
    },
    rubber: {
      name: "Rubber",
      type: "material",
      image: RubberImage,
      remarks: t('applications-page.remarks.rubber')
    },
    adhesives: {
      name: "Adhesives",
      image: AdhesiveImage,
      type: "application",
      remarks: t('applications-page.remarks.adhesives')
    },
    "paints and coatings": {
      name: "Paints and Coatings",
      image: PaintImage,
      type: "application",
      remarks: t('applications-page.remarks.paintsAndCoatings')
    },
    "automotive industry": {
      name: "Automotive Industry",
      image: AutomotiveImage,
      type: "application",
      remarks: t('applications-page.remarks.automotiveIndustry')
    },
    "3d printing": {
      name: "3D Printing",
      image: Printing3DImage,
      type: "processing",
      remarks: t('applications-page.remarks.3dPrinting')
    },
    "masterbatch/compounding": {
      name: "Masterbatch/Compounding",
      image: MasterBatchImage,
      type: "processing",
      remarks: t('applications-page.remarks.masterbatch/compounding')
    },
    "membrane technology": {
      name: "Membrane Technology",
      image: MembraneTechnologyImage,
      type: "application",
      remarks: t('applications-page.remarks.membraneTechnology')
    },
    "energy deviced and semiconductors": {
      name: "Energy Deviced and Semiconductors",
      image: EnergyImage,
      type: "application",
      remarks: t('applications-page.remarks.energyDevicedAndSemiconductors')
    },
    "recycling and sustainable materials effort": {
      name: "Recycling and Sustainable Materials Effort",
      image: RecycleImage,
      type: "processing",
      remarks: t('applications-page.remarks.recyclingAndSustainableMaterialsEffort')
    },
    "oil and gas industries": {
      name: "Oil and Gas Industries",
      image: OilAndGasImage,
      type: "application",
      remarks: t('applications-page.remarks.oilAndGasIndustries')
    },
    "packaging industries": {
      name: "Packaging Industries",
      image: FoodPackagingImage,
      type: "application",
      remarks: t('applications-page.remarks.packagingIndustries')
    },
    "textile engineering": {
      name: "Textile Engineering",
      image: TextileEngineeringImage,
      type: "application",
      remarks: t('applications-page.remarks.textileEngineering')
    }
  };

  const [activeItem, setActiveItem] = useState<Item>('polymers');
  const [activeItems, setActiveItems] = useState<Item[]>([]);

  useEffect(() => {
    const activeItemType = content[activeItem]?.type || "";

    const filteredItems = {};

    Object.keys(content).forEach((key) => {
      const item = content[key];

      if (item.type === activeItemType) {
        filteredItems[key] = item;
      }
    });

    setActiveItems(filteredItems);
  }, [activeItem])

  useEffect(() => {
    // Scroll to the section
    const sectionElement = document.getElementById(activeItem.toLowerCase());
    console.log(`sectionElement is: ${sectionElement}`)
    scroller.scrollTo(activeItem.toLowerCase(), {
      duration: 200,
      delay: 0,
      smooth: 'linear',
      offset: -150, // Offset from the top
    })
  }, [activeItems])

  const handleItemClick = (item: Item) => {
    setActiveItem(item);
  };

  const contactUsRef = useRef<HTMLDivElement>(null);
  const [showFloatingBanner, setShowFloatingBanner] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      if (contactUsRef.current) {
        const { top, bottom } = contactUsRef.current.getBoundingClientRect();
        const isVisible =
          top >= 0 &&
          bottom <= window.innerHeight &&
          window.scrollY > 0 &&
          bottom > window.innerHeight * 0.1;  // to show the banner when contact us is not visible
        setShowFloatingBanner(!isVisible);
      }
    };

    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);


  return (
    <main className="-mt-28 sm:-mt-36">
      <Container className="flex flex-col gap-16 pb-24 pt-48">
        <section className="relative flex md:flex-row sm:justify-between sm:flex-col flex-col items-center lg:text-left w-full lg:mx-auto">
          <div className='min-w-[60%] flex flex-col gap-4'>
              <h4 className="text-xl tracking-wider font-light w-48 mb-6 font-medium uppercase sm:text-xl md:text-xl bg-gradient-to-r from-theme-orange from-0% via-theme-orange via-10% to-theme-blue to-100% bg-clip-text text-transparent">
                  {t('applications-page.heading')}
              </h4>
              <p className="text-3xl sm:text-3xl sm:max-w-xl md:text-3xl min-w-[95%]">
                  {t('applications-page.desc')}
              </p>
              <h2 className="text-2xl tracking-wider uppercase sm:text-5xl md:text-5xl text-theme-orange font-black">
                  {t('applications-page.chemical-industry')}
              </h2>
          </div>
          <img
            src={ApplicationsHero}
            className="md:w-[40%] sm:w-[80%]"
            alt={t('applications-page.heading') + ' ' + t('common.words.image')}
        />
      </section>
        <div className="h-[1px] sm:block shrink-0 grow bg-gray-200" />
        <section className="flex flex-col sm:flex-row gap-10">
          <div className="flex flex-col">
            <Sidebar activeItem={activeItem} setActiveItem={setActiveItem} onItemClick={handleItemClick} />
            {/* <div className="flex flex-col gap-3 px-6 bg-gradient-to-br from-[#ECF2FF] from-[30%] via-[#ECF2FF] via-[70%] to-[#FFF7F1] to-[100%] p-4 mt-4">
              <SolutionsBannerIcon className="w-53 h-53"/>
              <h4 className="text-lg">{t('common.words.solutions')}</h4>
              <p className="leading-snug">{t('applications-page.solutionsBannerdesc')}</p>
              <Link to="/solutions/overview" className="flex flex-row text-theme-blue">
                <p>
                  {t('common.cta.knowMore')}
                </p>
                <ArrowRight />
              </Link>
            </div> */}
          </div>

          <div className="flex flex-col gap-16">
            <Content items={activeItems} />
          </div>
        </section>
        <div ref={contactUsRef}>
          <ContactUs />
        </div>
      </Container>


      <CtaBlock className="md:mt-[100px] lg:mt-0" />
      {showFloatingBanner && <Banner />}
    </main >
  );
}

export default Applications

// FOR LANGUAGE TRANSLATION
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
