import React, { Dispatch, SetStateAction } from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import { Item } from "pages/applications";
import { TranslationKey } from "types/translation";
import { ChevronDown, ChevronRight } from "react-feather";
import { useCollapse } from "react-collapsed";

type Props = {
  activeItem: Item;
  setActiveItem: Dispatch<SetStateAction<Item>>;
  options: ReadonlyArray<Item>;
  sectionHeader: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<string | null>>;
  onItemClick: (item: Item) => void; // Added prop for onItemClick function
};

export const SidebarSection = ({
  activeItem,
  setActiveItem,
  options,
  sectionHeader,
  isOpen,
  setIsOpen,
  onItemClick, // Added prop for onItemClick function
}: Props) => {
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded: isOpen });

  return (
    <div className="flex flex-col gap-8 w-full sm:w-60 md:w-80 shrink-0 px-4">
      <div className="flex flex-col gap-4">
        <button
          className={`text-lg text-neutral-600 flex items-center py-2 font-bold justify-between`}
          {...getToggleProps({
            onClick: () => setIsOpen(prev => prev === sectionHeader ? null : sectionHeader),
          })}
        >
          <div className="flex items-center">
            <div className={`bg-blue-500 w-1 h-5 mr-2 ${isOpen ? 'visible' : 'invisible'}`}></div>
            <span>{sectionHeader}</span>
          </div>
          {isOpen ? (
            <ChevronDown className="h-5 w-5" aria-hidden="true" />
          ) : (
            <ChevronRight className="h-5 w-5" aria-hidden="true" />
          )}
        </button>
        <div className="px-6 -mt-5" {...getCollapseProps()}>
          {options.map((item) => {
            return (
              <button
                key={item}
                className={`${item === activeItem ? "text-theme-blue" : "text-gray-400"
                  } w-full py-2 rounded-md text-left font-medium`}
                onClick={() => {
                  setActiveItem(item);
                  onItemClick(item); // Call the onItemClick function with the clicked item
                }}
              >
                <Trans
                  i18nKey={`${`applications-page.materials.${item}` as TranslationKey
                    }`}
                />
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
