import { PrimaryAlternativeBtn } from 'components/library';
import { Link } from 'gatsby';
import React from 'react'
import { Trans, useTranslation } from 'react-i18next';

const Banner = () => {
  const { t } = useTranslation()
  return <div className='w-screen fixed bottom-0 flex items-center justify-between pl-[4vw] pr-[4vw] pt-9 pb-9 sm:h-20 xl:h-12 text-white z-50 bg-banner-bg backdrop-blur'>
    <div className="flex">
      <div className='text-3xl mr-6 font-medium'>{t('contact-us.content.question-for-us')}</div>
      <div className='text-md w-80 leading-5'><Trans
						i18nKey="contact-us.content.check-resources"
						components={{
							anchor: <Link to="/support" />,
						}}
					/>.</div>
    </div>
    <Link to="/contact-us/">
      <PrimaryAlternativeBtn
        className="sm:text-md px-6 border-0 shadow shadow-[#1753E2] font-bold"
      >
        {t('common.words.contact-us')}
      </PrimaryAlternativeBtn>
    </Link>
  </div>
}

export default Banner;