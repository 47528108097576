import React from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { PrimaryAlternativeBtn } from "components/library";
import { InnovationIllustration } from "components/library/illustrations/innovation-illustration";

export const ContactUs = () => {
  const {t} = useTranslation()

  return (
    <section className="md:max-h-48 sm:max-h-full bg-gradient-to-b flex justify-between p-5 lg:py-10 md:py-4 sm:py-2 sm:px-5 lg:px-1 md:pr-5 lg:pr-5 bg-gradient-to-br from-[#3C67F0] from-[30%] via-[#CA8DDA] via-[70%] to-[#da8fa1] to-[100%] rounded-lg">
      <div className="w-full flex lg:flex-row xl-flex-row md:flex-row sm:flex-col flex-col gap-6 text-white items-center">
            <InnovationIllustration height='150%' width='80%'/>
            <div className="flex flex-col md:ml-[-50px]">
              <h1 className="lg:text-3xl md:text-2xl sm:text-xl max-w-[50%]">{t('contact-us.content.question-for-us')}</h1>
              <p className="text-xl">
              <Trans
                i18nKey="contact-us.content.check-resources"
                components={{
                  anchor: <Link to="/support" />,
                }}
              />
            </p>
            </div>
          <Link to="/contact-us/">
            <PrimaryAlternativeBtn
              onClick={close}
              tabIndex={-1}
              className="sm:text-lg px-6 border-none hover:text-white w-max mr-6"
            >
              {t('common.words.contact-us')}
            </PrimaryAlternativeBtn>
          </Link>
        </div>
    </section>
  );
};
